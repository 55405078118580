/** @jsx jsx */
import Layout from "../components/layout"
import { jsx } from 'theme-ui'

export default () => (
  <Layout title="Home">
    <p>
     Frontend web-developer who was into the JAM stack before it had a name.  Wordpress hater. Tailwind lover. If it's Emacs or Vim, I'll take Vim. </p>
  </Layout>
)

